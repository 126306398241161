import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { prismicGatsbyImageSharp } from '@graphql/fragments'
import { getSeoProps } from '../data/seo'
import { PageNotFound } from '../templates'
import useWindowWidth from '../hooks/useWindowWidth'

export const query = graphql`
  query PrismicNotFoundPage {
    prismic {
      allFour_oh_fours {
        edges {
          node {
            seodescription
            seotitle
            mobile_image
            desktop_image
            largeDeviceImage: desktop_imageSharp {
              childImageSharp {
                ...prismicGatsbyImageSharp
              }
            }
            smallDeviceImage: mobile_imageSharp {
              childImageSharp {
                ...prismicGatsbyImageSharp
              }
            }
          }
        }
      }
    }
  }
`

const NotFoundPage = ({ data }) => {
  NotFoundPage.fragments = [prismicGatsbyImageSharp]
  const nestedData = get(data, 'prismic.allFour_oh_fours.edges[0].node')

  if (!nestedData) {
    return null
  }

  const seo = getSeoProps(nestedData)
  const largeDeviceImage = get(nestedData, 'largeDeviceImage.childImageSharp.fluid')
  const smallDeviceImage = get(nestedData, 'smallDeviceImage.childImageSharp.fluid')
  const sharpImage = useWindowWidth() >= 767 ? largeDeviceImage : smallDeviceImage

  return <PageNotFound {...{ seo, sharpImage }} />
}

export default NotFoundPage

NotFoundPage.query = query
